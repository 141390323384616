import React, { useState } from 'react';
import { ChartMargin, DomainBounds } from '../chart/Chart';
import { monthInMs } from '../constants/Time';
import { Timeframe } from '../constants/Timeframe';
import Tool from '../Tool';
import LtvChart from './LtvChart';
import LtvSidebar from './LtvSidebar';
import { LtvToolbar } from './LtvToolbar';

const now = new Date();
const startDate = new Date(now.getFullYear(), now.getMonth(), 1);
const endDate = new Date(now.getFullYear() + 4, now.getMonth());

const domainBounds: DomainBounds = {
  yMin: 1,
  yMax: 3_200,
  xMin: startDate.getTime(),
  xMax: endDate.getTime(),
};

const chartMargin: ChartMargin = {
  left: 70,
  top: 30,
  right: 10,
  bottom: 50,
};

export interface LtvInputs {
  timeframe: Timeframe;
  setTimeframe: (timeframe: Timeframe) => void;
  acv: number;
  setAcv: (acv: number) => void;
  revenueGrowth: number;
  setRevenueGrowth: (revenueGrowth: number) => void;
  logoChurn: number;
  setLogoChurn: (logoChurn: number) => void;
  grossMargin: number;
  setGrossMargin: (grossMargin: number) => void;
  ltvToCac: number;
  setLtvToCac: (ltvToCac: number) => void;
}

export default function LtvCalculator() {
  const [timeframe, setTimeframe] = useState(Timeframe.Monthly);
  const [acv, setAcv] = useState(117);
  const [revenueGrowth, setRevenueGrowth] = useState(-0.0447);
  const [logoChurn, setLogoChurn] = useState(0.0535);
  const [grossMargin, setGrossMargin] = useState(0.8);
  const [ltvToCac, setLtvToCac] = useState(4);

  const ltv = getLtv(domainBounds, acv, revenueGrowth, logoChurn, grossMargin);

  return (
    <Tool
      Sidebar={
        <LtvSidebar
          timeframe={timeframe}
          setTimeframe={setTimeframe}
          acv={acv}
          setAcv={setAcv}
          revenueGrowth={revenueGrowth}
          setRevenueGrowth={setRevenueGrowth}
          logoChurn={logoChurn}
          setLogoChurn={setLogoChurn}
          grossMargin={grossMargin}
          setGrossMargin={setGrossMargin}
          ltvToCac={ltvToCac}
          setLtvToCac={setLtvToCac}
        />
      }
      Toolbar={
        <LtvToolbar
          domainBounds={domainBounds}
          acv={acv}
          logoChurn={logoChurn}
          ltvToCac={ltvToCac}
          ltv={ltv}
        />
      }
    >
      {({ width, height }) => (
        <LtvChart
          width={width}
          height={height}
          margin={chartMargin}
          domainBounds={domainBounds}
          timeframe={timeframe}
          setTimeframe={setTimeframe}
          acv={acv}
          setAcv={setAcv}
          revenueGrowth={revenueGrowth}
          setRevenueGrowth={setRevenueGrowth}
          logoChurn={logoChurn}
          setLogoChurn={setLogoChurn}
          grossMargin={grossMargin}
          setGrossMargin={setGrossMargin}
          ltvToCac={ltvToCac}
          setLtvToCac={setLtvToCac}
          ltv={ltv}
        />
      )}
    </Tool>
  );
}

function getLtv(
  domainBounds: DomainBounds,
  acv: number,
  revenueGrowth: number,
  logoChurn: number,
  grossMargin: number,
) {
  const { yMin, xMin } = domainBounds;
  const logoIntercept = xMin + ((acv - yMin) / (acv * logoChurn)) * monthInMs;
  const months = (logoIntercept - xMin) / monthInMs;
  function integral(months: number) {
    return (acv * (revenueGrowth + 1) ** months) / Math.log(revenueGrowth + 1);
  }
  const ltv = (integral(months) - integral(0)) * grossMargin;
  return ltv;
}
