import React from 'react';
import { DomainBounds } from '../chart/Chart';
import { monthInMs } from '../constants/Time';
import { Toolbar, ToolbarSection } from '../infobars/Toolbar';

interface LtvToolbarProps {
  domainBounds: DomainBounds;
  acv: number;
  logoChurn: number;
  ltvToCac: number;
  ltv: number;
}
export function LtvToolbar({
  domainBounds,
  acv,
  logoChurn,
  ltvToCac,
  ltv,
}: LtvToolbarProps) {
  const { yMin, xMin } = domainBounds;
  const logoIntercept = xMin + ((acv - yMin) / (acv * logoChurn)) * monthInMs;
  const months = (logoIntercept - xMin) / monthInMs;

  const cac = ltv / ltvToCac;

  const payback = cac / acv;

  return (
    <Toolbar>
      <ToolbarSection label="Customer Lifetime">
        {months.toFixed(1)} Months
      </ToolbarSection>
      <ToolbarSection label="LTV (Lifetime Value)">
        ${ltv.toFixed(2)}
      </ToolbarSection>
      <ToolbarSection label="CAC (Customer Aquisition Cost)">
        ${cac.toFixed(2)}
      </ToolbarSection>
      <ToolbarSection label="CAC Payback">
        {payback.toFixed(2)} Months
      </ToolbarSection>
    </Toolbar>
  );
}
