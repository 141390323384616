import React from 'react';
import { Timeframe } from '../constants/Timeframe';
import { LtvInputs } from './LtvCalculator';
import {
  Sidebar,
  SidebarInput,
  SidebarRatioInput,
  SidebarSelect,
  SidebarTitle,
  SidebarAnchor,
  SidebarTextInfo,
} from '../infobars/Sidebar';

import DollarIcon from '@icons/dollar.svg';
import PercentIcon from '@icons/percent.svg';

export default function LtvSidebar({
  timeframe,
  setTimeframe,
  acv,
  setAcv,
  revenueGrowth,
  setRevenueGrowth,
  logoChurn,
  setLogoChurn,
  grossMargin,
  setGrossMargin,
  ltvToCac,
  setLtvToCac,
}: LtvInputs) {
  return (
    <Sidebar>
      <SidebarTitle>Inputs</SidebarTitle>
      <SidebarSelect
        id="type"
        name="Contract Length"
        disabled
        value={timeframe}
        onChange={(e) => setTimeframe(e.target.value as Timeframe)}
      >
        {Object.entries(Timeframe).map(([key, value]) => (
          <option key={key} value={value}>
            {key}
          </option>
        ))}
      </SidebarSelect>
      <SidebarInput
        id="acv"
        name="ACV (Average Contract Value)"
        color="#f4c222"
        value={acv.toFixed(1)}
        onValueChange={({ floatValue }) => {
          if (floatValue) {
            setAcv(floatValue);
          }
        }}
      >
        <DollarIcon className="w-4 h-4 fill-current text-gray-400" />
      </SidebarInput>
      <SidebarInput
        id="revenueChurn"
        name="Revenue Churn"
        color="#a29bfe"
        decimal={2}
        isAllowed={({ floatValue }) => (floatValue ? floatValue < 100 : true)}
        value={-revenueGrowth * 100}
        onValueChange={({ floatValue }) => {
          if (floatValue) {
            setRevenueGrowth(-floatValue / 100 || 0);
          }
        }}
      >
        <PercentIcon className="w-4 h-4 fill-current text-gray-400" />
      </SidebarInput>
      <SidebarInput
        id="logoChurn"
        name="Logo Churn"
        color="#ffa0a0"
        decimal={2}
        value={logoChurn * 100}
        onValueChange={({ floatValue }) => {
          if (floatValue) {
            setLogoChurn(floatValue / 100 || 0);
          }
        }}
      >
        <PercentIcon className="w-4 h-4 fill-current text-gray-400" />
      </SidebarInput>
      <SidebarInput
        id="grossMargin"
        name="Gross Margin"
        color="#109d9d"
        decimal={0}
        value={grossMargin * 100}
        onValueChange={({ floatValue }) => {
          if (floatValue) {
            setGrossMargin(floatValue / 100 || 0);
          }
        }}
      >
        <PercentIcon className="w-4 h-4 fill-current text-gray-400" />
      </SidebarInput>
      <SidebarRatioInput
        id="ltv2cac"
        name="LTV:CAC"
        value={ltvToCac}
        onValueChange={({ floatValue }) => {
          if (floatValue) {
            setLtvToCac(floatValue);
          }
        }}
        rightValue={1}
      />
      <SidebarTextInfo label="Read More">
        <SidebarAnchor href="#example-1">Anchor Link to content</SidebarAnchor>
        <SidebarAnchor href="#example-2">
          Another Anchor Link to content
        </SidebarAnchor>
        <SidebarAnchor href="#example-3">
          Some other Anchor link to content down the page
        </SidebarAnchor>
      </SidebarTextInfo>
    </Sidebar>
  );
}
