import * as React from 'react';
import { graphql, PageProps } from 'gatsby';
import Layout from '@components/Layout';
import SliceZone from '@components/SliceZone';
import { LtvCalculatorQuery } from '../../../graphql-types';
import LtvCalculator from '@components/Calculator/ltvCalculator/LtvCalculator';

type Props = PageProps<LtvCalculatorQuery>;

export default function StartupLtvCalculator({ data }: Props) {
  const { page_title, page_description, page_image } =
    data.prismicLtvCalculator.data;

  return (
    <Layout
      banner={data.prismicBanner}
      nav={data.prismicNav}
      footer={data.prismicFooter}
      metadata={{
        title: page_title.text,
        description: page_description.text,
        imageUrl: page_image.url,
        lang: 'en',
      }}
    >
      <LtvCalculator />
      <div className="layout-margin">
        <SliceZone slices={data.prismicLtvCalculator.data.body} />
      </div>
    </Layout>
  );
}

export const query = graphql`
  query LtvCalculator($lang: String) {
    prismicLtvCalculator {
      _previewable
      id
      prismicId
      type
      data {
        page_title {
          text
        }
        page_description {
          text
        }
        page_image {
          url
        }
        body {
          ...TextWithReferencesLtvSlice
        }
      }
    }
    prismicNav(lang: { eq: $lang }) {
      ...NavFragment
    }
    prismicFooter(lang: { eq: $lang }) {
      ...FooterFragment
    }
    prismicBanner {
      ...PrismicBannerFragment
    }
  }
`;
