import React from 'react';
import Chart, {
  ChartMargin,
  DomainBounds,
  useChartScales,
} from '../chart/Chart';
import { Group } from '@visx/group';
import { BottomAxis, LeftAxis } from '../chart/Axis';
import { DragHandleWithAnnotation } from '../chart/DragHandle';
import { format } from 'd3-format';
import { clamp, pointOnCircle } from '../chart/util';
import { PaddedPath, partialCirclePath, TargetCircle } from '../chart/Shapes';
import { LtvInputs } from './LtvCalculator';
import { FillArea } from '../chart/FillArea';
import { ForeignObject } from '../chart/ForeignObject';
import { monthFormat, monthInMs } from '../constants/Time';

enum InterceptType {
  End,
  Top,
  Bottom,
}

const acvColor = '#f4c222';
const revenueColor = '#a29bfe';
const logoColor = '#ffa0a0';

interface LtvChartProps extends LtvInputs {
  width: number;
  height: number;
  margin: ChartMargin;
  domainBounds: DomainBounds;
  ltv: number;
}
export default function LtvChart({
  width,
  height,
  margin,
  domainBounds,
  acv,
  setAcv,
  revenueGrowth,
  setRevenueGrowth,
  logoChurn,
  setLogoChurn,
  grossMargin,
  ltv,
}: LtvChartProps) {
  const revenueRadius = width / 4;
  const { xMin, xMax, yMin, yMax } = domainBounds;

  const { xScale, yScale } = useChartScales(
    width,
    height,
    margin,
    domainBounds,
  );

  const logoInterceptTime =
    xMin + ((acv - yMin) / (acv * logoChurn)) * monthInMs;

  const revenueAtLogoIntercept = getDomainYAtTimeX(
    domainBounds,
    acv,
    revenueGrowth,
    logoInterceptTime,
  );

  const logoInterceptType = getLogoInterceptType(
    domainBounds,
    revenueAtLogoIntercept,
  );

  const revenueInterceptType = getRevenueInterceptType(
    domainBounds,
    revenueGrowth,
    acv,
  );

  const [revenueEndTime, revenueEndRevenue] = getGrowthLineEnd(
    domainBounds,
    acv,
    revenueGrowth,
    revenueInterceptType,
  );

  const revenueAngle = Math.atan(
    (yScale(revenueEndRevenue) - yScale(acv)) / xScale(revenueEndTime),
  );
  const revenueHandlePoint = pointOnCircle(
    xScale(xMin),
    yScale(acv),
    revenueRadius,
    revenueAngle,
  );

  const isLogoInterceptInRange =
    revenueAtLogoIntercept > yMin && revenueAtLogoIntercept < yMax;

  const revenueXIntercept =
    (Math.log(yMin / acv) / Math.log(1 + revenueGrowth)) * monthInMs + xMin;

  let fillAreaPoints;
  switch (logoInterceptType) {
    case InterceptType.Top:
      fillAreaPoints = [
        [xScale(xMin), yScale(acv)],
        [xScale(revenueEndTime), yScale(revenueEndRevenue)],
        [xScale(logoInterceptTime), yScale(yMax)],
        [xScale(logoInterceptTime), yScale(yMin)],
        [xScale(xMin), yScale(yMin)],
      ];
      break;
    case InterceptType.End:
      fillAreaPoints = [
        [xScale(xMin), yScale(acv)],
        [xScale(logoInterceptTime), yScale(revenueAtLogoIntercept)],
        [xScale(logoInterceptTime), yScale(yMin)],
        [xScale(xMin), yScale(yMin)],
      ];
      break;
    case InterceptType.Bottom:
      fillAreaPoints = [
        [xScale(xMin), yScale(acv)],
        [xScale(revenueXIntercept), yScale(yMin)],
        [xScale(xMin), yScale(yMin)],
      ];
      break;
  }

  return (
    <Chart height={height} width={width}>
      <Group left={margin.left} top={margin.top}>
        <FillArea points={fillAreaPoints.join(' ')} />

        <LeftAxis
          scale={yScale}
          tickValues={[
            1, 1.5, 3, 6, 12, 25, 50, 100, 200, 400, 800, 1_600, 3_200,
          ]}
        />
        <BottomAxis scale={xScale} top={yScale.range()[0]} />

        {/* Revenue line */}
        <PaddedPath
          stroke={revenueColor}
          d={`M${xScale(xMin)},${yScale(acv)}L${xScale(
            revenueEndTime,
          )},${yScale(revenueEndRevenue)}`}
        />
        {/* Revenue arc */}
        <PaddedPath
          stroke={revenueColor}
          d={partialCirclePath(
            xScale(xMin),
            yScale(acv),
            revenueRadius,
            -Math.PI / 4,
            Math.PI / 4,
          )}
        />
        {/* Dotted line */}
        <PaddedPath
          stroke="#d1d8e0"
          strokeDasharray={'6,4'}
          d={`M${xScale(logoInterceptTime)},${yScale(yMin)}L${xScale(
            logoInterceptTime,
          )},${yScale(clamp(revenueAtLogoIntercept, yMin, yMax))}`}
        />
        {/* Revenue intercept target */}
        {isLogoInterceptInRange && (
          <TargetCircle
            x={xScale(logoInterceptTime)}
            y={yScale(revenueAtLogoIntercept)}
            fill="#3d7dff"
          />
        )}
        {/* Months label */}
        <ForeignObject
          x={xScale(logoInterceptTime)}
          y={yScale(yMin)}
          placement="top-start"
          offset={12}
        >
          <div className="bg-white p-2 rounded mr-3 flex items-center space-x-2">
            <span className="whitespace-nowrap text-xs font-medium text-gray-900 capitalize">
              {monthFormat.format((logoInterceptTime - xMin) / monthInMs)}
            </span>
          </div>
        </ForeignObject>
        {/* LTV label */}
        <ForeignObject
          x={xScale(xMin)}
          y={yScale(yMin)}
          placement="top-end"
          offset={{ mainAxis: 12, crossAxis: 12 }}
        >
          <div className="bg-white p-2 rounded mr-3 flex items-center space-x-2">
            <span className="whitespace-nowrap text-xs font-medium text-gray-900 capitalize">
              LTV: ${format('.2f')(ltv)} (${format('.2f')(ltv * grossMargin)} x{' '}
              <span className="text-green-500">{grossMargin * 100}%</span>)
            </span>
          </div>
        </ForeignObject>
      </Group>
      {/*
        Place drag handles outside of the group above 
        so they can be moved beyond inner chart bounds. 
      */}

      {/* ACV handle */}
      <DragHandleWithAnnotation
        color={acvColor}
        annotationLabel={`$${format('.3s')(acv)}`.toUpperCase()}
        width={width}
        height={height}
        x={margin.left + xScale(xMin)}
        y={margin.top + yScale(acv)}
        onDragMove={(e) => {
          const { y = 0, dy } = e;
          const dragValue = yScale.invert(y + dy - margin.top);
          const constrainedValue = clamp(dragValue, yMin, yMax);
          setAcv(constrainedValue);
        }}
      />

      {/* Revenue handle */}
      <DragHandleWithAnnotation
        color={revenueColor}
        width={width}
        height={height}
        x={margin.left + revenueHandlePoint.x}
        y={margin.top + revenueHandlePoint.y}
        annotationLabel={`${format('.2%')(-revenueGrowth)}`.toUpperCase()}
        onDragMove={(e) => {
          const { x = 0, y = 0, dx, dy } = e;
          const handleDate = xScale.invert(x + dx - margin.left);
          const handleTime = clamp(handleDate.getTime(), xMin, xMax);
          const handleRevenue = yScale.invert(y + dy - margin.top);
          const months = (handleTime - xMin) / monthInMs;
          const growth = (handleRevenue / acv) ** (1 / months) - 1;
          const clampedGrowth = clamp(
            growth,
            Number.EPSILON - 1,
            Number.MAX_SAFE_INTEGER,
          );
          setRevenueGrowth(clampedGrowth);
        }}
      />

      {/* Logo Handle */}
      <DragHandleWithAnnotation
        color={logoColor}
        width={width}
        height={height}
        x={margin.left + xScale(logoInterceptTime)}
        y={margin.top + yScale(yMin)}
        annotationLabel={`${format('.2%')(logoChurn)}`.toUpperCase()}
        onDragMove={(e) => {
          const { x = 0, dx } = e;
          const time = xScale.invert(x + dx - margin.left).getTime();
          const clampedTime = clamp(time, xMin, xMax);
          const months = (clampedTime - xMin) / monthInMs;
          const logoChurn = 1 / months;
          setLogoChurn(logoChurn);
        }}
      />
    </Chart>
  );
}

function getRevenueInterceptType(
  domainBounds: DomainBounds,
  revenueGrowth: number,
  acv: number,
) {
  const { xMin, xMax, yMin, yMax } = domainBounds;
  let revenueInterceptType = InterceptType.End;
  const monthsOnChart = (xMax - xMin) / monthInMs;
  if (revenueGrowth > 0) {
    const interceptMonth = Math.log(yMax / acv) / Math.log(1 + revenueGrowth);
    const isInterceptInChart =
      interceptMonth > 0 && interceptMonth <= monthsOnChart;
    if (isInterceptInChart) {
      revenueInterceptType = InterceptType.Top;
    }
  } else {
    const interceptMonth = Math.log(yMin / acv) / Math.log(1 + revenueGrowth);
    const isInterceptInChart =
      interceptMonth > 0 && interceptMonth <= monthsOnChart;
    if (isInterceptInChart) {
      revenueInterceptType = InterceptType.Bottom;
    }
  }
  return revenueInterceptType;
}

function getLogoInterceptType(
  domainBounds: DomainBounds,
  revenueAtLogoIntercept: number,
) {
  const { yMin, yMax } = domainBounds;
  if (revenueAtLogoIntercept < yMin) {
    return InterceptType.Bottom;
  } else if (revenueAtLogoIntercept > yMax) {
    return InterceptType.Top;
  } else {
    return InterceptType.End;
  }
}

function getGrowthLineEnd(
  domainBounds: DomainBounds,
  originDomain: number,
  growth: number,
  revenueInterceptType: InterceptType,
) {
  const { xMin, xMax, yMin, yMax } = domainBounds;
  const monthsOnChart = (xMax - xMin) / monthInMs;

  let xInterceptMonths: number;
  switch (revenueInterceptType) {
    case InterceptType.End:
      xInterceptMonths = monthsOnChart;
      break;
    case InterceptType.Top:
      xInterceptMonths = Math.log(yMax / originDomain) / Math.log(1 + growth);
      break;
    case InterceptType.Bottom:
      xInterceptMonths = Math.log(yMin / originDomain) / Math.log(1 + growth);
      break;
  }

  const lineEndX = xMin + xInterceptMonths * monthInMs;
  const lineEndY = originDomain * (1 + growth) ** xInterceptMonths;

  return [lineEndX, lineEndY];
}

function getDomainYAtTimeX(
  domainBounds: DomainBounds,
  originDomain: number,
  growth: number,
  time: number,
) {
  const months = (time - domainBounds.xMin) / monthInMs;
  return originDomain * (growth + 1) ** months;
}
