import React from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

import InfoIcon from '@icons/info.svg';

export function Toolbar({ children }: { children: React.ReactNode }) {
  return (
    <div className="flex h-28  divide-x divide-blue-100 whitespace-nowrap overflow-auto">
      {children}
    </div>
  );
}

interface ToolbarSectionProps {
  label: string;
  tooltip?: string;
  children?: React.ReactNode;
}
export function ToolbarSection({
  label,
  tooltip,
  children,
}: ToolbarSectionProps) {
  return (
    <div className="h-full flex flex-col justify-center px-6 space-y-3 flex-1">
      <div className="flex space-x-2">
        <span className="text-xs text-gray-600">{label}</span>
        {tooltip && (
          <Tippy content={tooltip}>
            <div>
              <InfoIcon className="w-4 h-4 fill-current text-gray-400" />
            </div>
          </Tippy>
        )}
      </div>
      <h3 className="text-2xl font-medium">{children}</h3>
    </div>
  );
}
